import React from "react"
import { Col } from "react-bootstrap"
import { Link } from "gatsby"
import "./blog.css"
import styles from '../../components/OurTeamPage/OurTeam.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons"


export default ({ article }) => (
  <>
    <div className="article-container">
      
        <Col sm={3}>
        <div className="article-thumbnail">
        <Link to={`/blog/${article.slug}`}>
          <img
            src={article.thumbnail ? article.thumbnail.fixed.src : null}
            style={{ width: "400" }}
            alt={article.image.title}
            className={styles.round}
          />
        </Link>
      </div>
        
        
        <div className="article-preview-text">
        <div style={{ marginBottom: "0px" }}>
          <Link className={styles.postTitle} to={`/blog/${article.slug}`}>
            {article.title}
          </Link>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <small>{article.updatedAt}</small>
        </div>
        <p className={styles.articleExcerpt}>{article.excerpt}</p>
        <div>
          <p><Link to={`/blog/${article.slug}`} className={styles.readMore}>Read More <FontAwesomeIcon icon={faLongArrowRight} aria-hidden="true" style={{paddingTop: '4px'}} /></Link></p>
                        </div>
      </div>
      
    {/* <div
      style={{ borderBottom: "1px solid lightgrey", marginBottom: "20px" }}
    /> */}
        </Col>
      
      </div>      
  </>
)
