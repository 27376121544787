import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import ArticlePreview from "../components/Blog/ArticlePreview"
import Pagination from "../components/Blog/Pagination"

const Blog = ({ data, pageContext }) => {

  const posts = data.allContentfulBlogPost.edges
  // const { humanPageNumber } = pageContext
  //const isFirstpage = humanPageNumber === 1
  return (

  <Layout pageTitle="Teton Valley Aquatics News">
    <SEO title="Teton Valley Aquatics News" />
    <Container className="my-4">
      <Row>
        <Col>
        { posts.map(({ node }) => {
       return (
           <ArticlePreview article={node} />
       )
     }) }
        </Col>
      </Row>

<Row className="my-4"
  style={{
    margin: '4rem 0 4rem 0'
  }}
>
  <Col>
  <Pagination context={pageContext} />
  </Col>
</Row>

    </Container>
   </Layout>

  
  )
}

export const getBlogPost = graphql`
query ($skip: Int, $limit: Int) {
    allContentfulBlogPost (
      sort: { fields: [updatedAt], order: DESC }
      limit: $limit
      skip: $skip
      ) {
      totalCount
      edges {
        node {
          slug
          title
          updatedAt(formatString: "dddd MMMM Do, YYYY")
          excerpt
          thumbnail: postThumbnail {
            fixed(width: 400) {
              src
            }
          }
          image: featuredImage {
            fluid(maxWidth: 600) {
              src
              srcSet
            }
          }
        }
      }
    }
  }
`

export default Blog
